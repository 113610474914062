.loading-container,
a .loading-container {
	display: flex;
	align-items: center;
	flex-direction: column;
	justify-content: space-between;
	color: #e52028;

	.loading-text {
		color: white;
		font-size: 20px;
	}
	/* ---------------------------------------------------------- */
	/* 3 balls Horizontal spinner */
	.horizontal-spinner {
		display: inline-block;
		position: relative;
		width: 80px;
		height: 80px;
	}

	.horizontal-spinner div {
		position: absolute;
		top: 33px;
		width: 13px;
		height: 13px;
		border-radius: 50%;
		background: #fff;
		animation-timing-function: cubic-bezier(0, 1, 1, 0);
	}

	.horizontal-spinner div:nth-child(1) {
		left: 8px;
		animation: horizontal-spinner1 0.6s infinite;
	}

	.horizontal-spinner div:nth-child(2) {
		left: 8px;
		animation: horizontal-spinner2 0.6s infinite;
	}

	.horizontal-spinner div:nth-child(3) {
		left: 32px;
		animation: horizontal-spinner2 0.6s infinite;
	}

	.horizontal-spinner div:nth-child(4) {
		left: 56px;
		animation: horizontal-spinner3 0.6s infinite;
	}

	@keyframes horizontal-spinner1 {
		0% {
			transform: scale(0);
		}

		100% {
			transform: scale(1);
		}
	}

	@keyframes horizontal-spinner3 {
		0% {
			transform: scale(1);
		}

		100% {
			transform: scale(0);
		}
	}

	@keyframes horizontal-spinner2 {
		0% {
			transform: translate(0, 0);
		}

		100% {
			transform: translate(24px, 0);
		}
	}
}

/*.loader {
	width: 100%;
	height: 100%;
	position: relative;

	& > * {
		display: block;
		position: absolute;
		bottom: 50%;
		margin-bottom: -50%;
		left: 50%;
		margin-left: -50%;
	}
}*/
