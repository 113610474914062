﻿$color-black: #000000;
$color-white: #ffffff;
$dark-font-color: rgb(67, 67, 67);
$light-font-color: $color-white;
$grey-font: rgb(108, 108, 108);

$theme-color: #e52028;
$theme-color-darker: darken($theme-color, 20%);

$theme-background: $color-black;
$theme-foreground: $color-white;
$theme-shadow-color: rgba(229, 32, 40, 0.25);

$wd-button-filled-color: $theme-foreground;
$wd-button-filled-bg-color: $theme-color;
$wd-button-filled-border-color: $theme-color;

$wd-button-outline-color: $theme-color;
$wd-button-outline-bg-color: $theme-background;
$wd-button-outline-border-color: $theme-color;

$rating-color-dark: $dark-font-color;
$rating-color-light: $light-font-color;

$rating-bg-color: transparent;
$rating-border-color: transparent;
$rating-color: $rating-color-dark;
$rating-selected-bg-color: $rating-color-dark;
$rating-selected-border-color: $rating-color-dark;
$rating-selected-color: $rating-color-light;

$adscore-very-strong-color: #5ec445;
$adscore-strong-color: #abd550;
$adscore-moderate-color: #f1d02f;
$adscore-weak-color: #df6b86;
$adscore-very-weak-color: #e50814;

$adscore-theme-color1: black;
$adscore-theme-color2: $adscore-theme-color1; //#7458bb;
$adscore-filter-bg: $adscore-theme-color1;
$adscore-filter-title-color: #202020;
$adscore-font-color: #202020;

$adscore-button-bg: $adscore-theme-color1;
$adscore-button-disabled-bg: lighten($adscore-theme-color1, 75%);
$adscore-button-color: white;

$adscore-link-button-bg: white;
$adscore-link-button-color: $adscore-theme-color2;

$adscore-title-color: $adscore-theme-color2;

%very-strong-color {
	color: $adscore-very-strong-color;
}

%strong-color {
	color: $adscore-strong-color;
}

%moderate-color {
	color: $adscore-moderate-color;
}

%weak-color {
	color: $adscore-weak-color;
}

%very-weak-color {
	color: $adscore-very-weak-color;
}

%bg-transparent {
	background-color: transparent;
}

%font-bold {
	font-weight: bold;
}

%helvetica {
	font-family: 'Helvetica';
}

%helvetica-bold {
	@extend %font-bold;
	font-family: 'Helvetica-Bold';
}

%helvetica-neue {
	font-family: 'HelveticaNeue';
}

%helvetica-neue-bold {
	@extend %font-bold;
	font-family: 'HelveticaNeue-Bold';
	/*font-family: 'Helvetica-Bold';*/
}

%helvetica-neue-thin {
	font-family: 'HelveticaNeue-Thin';
}

%helvetica-thin {
	@extend %helvetica-neue-thin;
}

%helvetica-neue-medium {
	font-family: 'HelveticaNeue-Medium';
}

%helvetica-medium {
	@extend %helvetica-neue-medium;
}
