@import '_variables';

.modal-content {
	background-color: rgba(0, 0, 0, 0.9);
	border: 1px solid;
	border-radius: 0;
	padding: 2rem;
}

.modal-dialog {
	&.modal-opaque {
		.modal-content {
			background-color: rgba(0, 0, 0, 0.9);
		}
	}
}

.locked-modal .modal-content {
	background-color: $color-white;
	color: rgb(67, 67, 67);
	border: 0;
	border-radius: 10px;
}

.locked-modal .modal-title {
	color: $color-black;
}

.locked-modal.modal-dialog {
	width: 90%;
	max-width: 1400px;
}

.locked-modal .modal-content {
	flex-direction: row;
	padding: 2rem;
}

.locked-modal .modal-content .modal-img {
	width: 100px;
	padding-top: 25px;
}

.locked-modal .modal-content .modal-img-upgrade {
	width: 130px;
}

.locked-modal .modal-content {
	font-size: 30px;
}

.locked-modal.unlock-denied .modal-content {
	padding-left: 0;
}

.locked-modal .modal-content p {
	margin-bottom: 0;
}

.locked-modal .submit {
	box-shadow: 0 8px 6px -6px #777;
	margin-top: 40px;
	min-width: 300px;
	max-width: 100%;
}

.locked-modal .modal-text {
	width: calc(100% - 100px);
	padding-left: 30px;
}

.locked-modal .close-modal {
	color: rgb(67, 67, 67);
	border-color: rgb(67, 67, 67);
}

.modal-header {
	border: 0;
}

.modal-title {
	font-size: 57px;
	line-height: 1.1;
}

.modal-content .form-control {
	background-color: rgb(51, 51, 51);
	border: 0;
	font-size: 24px;
	padding: 0.375rem 1rem;
	color: rgb(169, 169, 169);
}

.modal-content .submit {
	font-size: 26px;
}

.modal-content .text-muted {
	color: rgb(169, 169, 169) !important;
}

.close-modal {
	@extend %bg-transparent;
	border: 1px solid $color-white;
	color: $color-white;
	font-size: 23px;
	cursor: pointer;
	border-radius: 50%;
	width: 40px;
	height: 40px;
	padding: 0;
}

.close-modal span {
	margin-bottom: 2px;
}

.modal .btn-wisdrop-filled:hover {
	background-color: rgb(202, 19, 27);
	color: $color-white;
}

.modal-header {
	border-bottom: 0;
}

.modal-title {
	font-size: 44px;
}

.modal-content .form-control {
	font-size: 20px;
}

.modal-content .submit {
	font-size: 22px;
}

@media (max-width: 576px) {
	.modal-title {
		font-size: 28px;
	}

	.modal-content {
		padding: 0.5rem;
	}

	#slideModal .modal-dialog {
		max-width: 80%;
	}
}

#feedbackModal .modal-dialog {
	max-width: 600px;
}

#feedbackModal .modal-title {
	font-size: 40px;
}

#feedbackModal label {
	color: rgb(169, 169, 169);
}

#feedbackModal .btn {
	margin-top: 25px;
}

@media (max-width: 767.98px) {
	.modal-content .form-control,
	.form-control {
		font-size: 18px;
	}

	.modal-content .submit,
	form .submit {
		font-size: 18px;
	}

	.modal-title {
		font-size: 35px;
	}

	.modal-content {
		padding: 1rem;
	}

	.close-modal {
		font-size: 16px;
		width: 30px;
		height: 30px;
	}
}
